<template>
  <v-col class="mx-1 my-3  ">
    <v-card>
      <v-row class="px-2 py-2">
        <v-spacer />

        <v-col
          cols="12"
          sm="4"
          md="3"
          lg="2"
          xl="2"
          class="  py-0 my-0 mb-2"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{on, attrs}">
              <v-text-field
                v-model="date"
                label="Tarih"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                hide-details
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
            >
              <v-spacer />
              <Button
                :text="true"
                color="primary"
                @click="menu = false"
              >
                Kapat
              </Button>
              <Button
                :text="true"
                color="primary"
                @click="$refs.menu.save(date)"
              >
                Seç
              </Button>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="4"
          md="3"
          lg="2"
          xl="2"
          class="  py-0 my-0 mb-2"
        >
          <v-dialog
            ref="dialog"
            v-model="hourmodal2"
            :return-value.sync="hourmodel"
            persistent
            width="290px"
          >
            <template v-slot:activator="{on, attrs}">
              <v-text-field
                v-model="hourmodel"
                label="Saat"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                outlined
                dense
                v-bind="attrs"
                hide-details
                v-on="on"
              />
            </template>
            <v-time-picker
              v-if="hourmodal2"
              v-model="hourmodel"
              full-width
              :allowed-minutes="allowedStep"
              format="24hr"
            >
              <v-spacer />
              <Button
                :text="true"
                color="primary"
                @click="hourmodal2 = false"
              >
                Kapat
              </Button>
              <Button
                :text="true"
                color="primary"
                @click="$refs.dialog.save(hourmodel)"
              >
                Seç
              </Button>
            </v-time-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          sm="5"
          md="3"
          lg="3"
          xl="2"
          class="  py-0 my-0 mb-2"
        >
          <Select
            v-model="santralModel"
            :items="santralList"
            label="Santral"
            :hide-details="true"
            outlined
            dense
          />
        </v-col>

        <v-col
          cols="6"
          sm="2"
          md="2"
          lg="2"
          xl="1"
          class="  py-0 my-0  mb-2"
        >
          <Button
            color="primary"
            @click="getInverterList"
          >
            Filtrele
          </Button>
        </v-col>
      </v-row>

      <v-col
        v-if="headers && desserts"
        cols="12"
      >
        <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="itemsPerPage"
          dense
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:item.CommStatusCode="{item}">
            <div v-if="item.CommStatusCode > 0">
              <i
                style="font-size: 20px"
                class="mdi mdi-checkbox-marked-circle green--text"
              />
            </div>

            <div v-else>
              <i
                style="font-size: 20px"
                class="mdi mdi-checkbox-marked-circle red--text"
              />
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-card>
  </v-col>
</template>

<script>
import moment from 'moment';
import i18n from '@/locale';
import helper from '@/api/helper';
import {
  getIntervalInverter,
} from '@/api/Inverter/Common';
import {
  // eslint-disable-next-line import/named
  getInverterLiveData,
} from '@/api/Inverter/InverterLive';

export default {
  components: {},
  data: () => ({
    desserts: [],
    santralModel: [],
    itemsPerPage: 10,
    date: moment().format('YYYY-MM-DD'),
    menu: false,
    modal: false,
    componentKey: 1,
    hourmenu: false,
    hourmodel: moment().format('HH:mm'),
    time: null,
    menu2: false,
    hourmodal2: false,
    step_time: 5,
    santralInfo: [],
    inverterData: [],
  }),
  computed: {
    santralList() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralList = [];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties

      santralRawData.forEach((item) => {
        santralList.push({
          text: item.santral,
          value: item.santralCode,
        });
      });

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.santralModel = santralList[0].value;
      // eslint-disable-next-line consistent-return
      return santralList;
    },
    santralData() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralData = {};
      santralRawData.forEach((item) => {
        santralData[item.santralCode] = item;
      });
      // eslint-disable-next-line consistent-return
      return santralData;
    },
    headers() {
      return [
        {
          text: i18n.t('general.connection'),
          value: 'CommStatusCode',
          width: '5 px',
        },
        { text: 'INVERTER', value: 'INVERTERNAME' },
        { text: 'INVERTER', value: 'INVERTER', display: 'none' },
        { text: i18n.t('Manufacture'), value: 'inverterManufacture' },
        { text: 'Model', value: 'inverterDeviceModel' },
        { text: 'Durumu', value: 'inverterStatus' },
        { text: 'Seri N.', value: 'Serial' },

        {
          text: `${i18n.t('TheoreticalPower')}(kW)`,
          value: 'TheoreticalPower',
        },
        {
          text: `${i18n.t('PerformanceRatio')}(%)`,
          value: 'PerformanceRatio',
        },
        { text: 'Efficiency', value: 'Efficiency' },
        {
          text: `${i18n.t('ACPower')}(kW/kWp)`,
          value: 'ACPower',
        },
        {
          text: `${i18n.t('DCPower')}(A/kWp)`,
          value: 'DCPower',
        },
        {
          text: `${i18n.t('Temperature')}(°C)`,
          value: 'TempBoard',
        },
        // eslint-disable-next-line no-useless-concat
        { text: 'LifeTime' + '(dk)', value: 'LifeTime' },
        {
          text: `${i18n.t('DailyEnergy')}(kW)`,
          value: 'DailyEnergy',
        },
        {
          text: `${i18n.t('TotalEnergy')}(kW)`,
          value: 'TotalEnergy',
        },
      ];
    },
  },
  watch: {
    hourmodel() { },
    santralModel() {
      this.getInverterList();
    },
  },
  created() {
    this.setIntervalInverter();
  },
  mounted() {
    setTimeout(() => {
      this.getInverterList();
    }, 3000);
  },
  methods: {
    setIntervalInverter() {
      this.hourmodel = getIntervalInverter();
    },
    allowedMinutes: (v) => v >= 10 && v <= 50,
    allowedStep: (m) => m % 10 === 0,
    async getInverterList() {
      this.desserts = [];
      this.itemsPerPage = 10;
      try {
        const payload = {
          santral: this.santralData[this.santralModel].santral,
          Time: this.date,
          hour: this.hourmodel,
        };

        const { desserts, itemsPerPage } = await getInverterLiveData(this.headers, payload);
        if (!helper.is_Empty(desserts)) {
          this.desserts = desserts;
        }
        this.itemsPerPage = itemsPerPage;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
