import helper from '@/api/helper';
import store from '@/store';
import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export function getInverterList() {
  const { inverterData } = store.getters;
  const inverterList = [];
  Object.keys(inverterData).forEach((i) => {
    if (inverterList.indexOf(inverterData[i].INVERTER) === -1) {
      inverterList.push(inverterData[i].INVERTER);
    }
  });

  return inverterList;
}

export function getIntervalInverter() {
  const { selectedFarm } = store.getters;
  let hour = moment().format('HH:00');
  if (selectedFarm !== undefined && Object.keys(selectedFarm).length > 0) {
    const stepTime = helper.getFarmStep(selectedFarm.prefix);
    // eslint-disable-next-line radix
    const minute = parseInt(moment().format('mm'));

    let minuteGrup = 0;
    for (let mng = 0; mng < 60; mng += stepTime) {
      if (minute >= mng) {
        minuteGrup = mng;
      }
    }
    let m;

    // eslint-disable-next-line no-unused-expressions
    minuteGrup < 10 ? (m = `0${minuteGrup}`) : (m = minuteGrup);
    hour = moment().format('HH:') + m;
  }
  return hour;
}
