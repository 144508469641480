import store from '@/store';
import * as Inverter from '@/api/Inverter/Inverter';
import { getInverterList } from '@/api/Inverter/Common';
import helper from '@/api/helper';

// inverter anlık durumu
// eslint-disable-next-line import/prefer-default-export
export async function getInverterLiveData(headers, payload) {
  const inverterList = getInverterList();
  const { inverterData } = store.getters;
  const column = headers.map((item) => item.value);
  const itemsPerPage = inverterList.length;

  const formData = {
    condiniton: {
      santral: payload.santral,
      Time: payload.Time,
      hour: payload.hour,
      inverterList,
      limit: inverterList.length,
      orderBys: [{ fieldName: 'datetimestamp', sortOrder: 'desc' }],
      column,
    },
  };

  const res = await Inverter.default.getInverterLive(formData);

  const restt = {};
  Object.keys(res).forEach((item) => {
    if (res[item].INVERTER !== undefined) {
      restt[res[item].INVERTER] = res[item];
    }
  });
  const desserts = [];

  Object.keys(inverterData).forEach((item) => {
    if (inverterData[item].santral === payload.santral) {
      desserts.push(inverterData[item]);
    }
  });

  Object.keys(res).forEach((item) => {
    if (res[item].INVERTER !== undefined) {
      restt[res[item].INVERTER] = res[item];
    }
  });

  Object.keys(desserts).forEach((item) => {
    let source = {
      ACPower: 0,
      CommStatusCode: 0,
      DailyEnergy: 0,
      Efficiency: 0,
      LifeTime: 0,
      TheoreticalPower: 0,
      PerformanceRatio: 0,
      TempBoard: 0,
      TotalEnergy: 0,
      DCPower: 0,
    };

    if (restt[desserts[item].INVERTER] !== undefined) {
      const selectedval = restt[desserts[item].INVERTER];
      source = {
        ACPower: helper.setFixed(selectedval.ACPower, 2),
        DCPower: helper.setFixed(selectedval.DCPower, 2),
        CommStatusCode: selectedval.CommStatusCode,
        DailyEnergy: helper.setFixed(selectedval.DailyEnergy, 2),
        Efficiency: selectedval.Efficiency,
        LifeTime: selectedval.LifeTime,
        TheoreticalPower: helper.setFixed(
          selectedval.TheoreticalPower,
          2,
        ),
        PerformanceRatio: helper.setFixed(selectedval.PerformanceRatio),
        TempBoard: selectedval.TempBoard,
        TotalEnergy: helper.setFixed(selectedval.TotalEnergy, 3),
      };

      if (helper.is_Empty(source.CommStatusCode)) {
        source.CommStatusCode = selectedval.ACPower > 0 ? 1 : 0;
      }
    }
    const target = desserts[item];
    desserts[item] = Object.assign(target, source);
  });

  return { desserts, itemsPerPage };
}
